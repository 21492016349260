import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'


posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string,
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST as string,
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
