import SignIn from "./components/SignIn";
import { createClient, User } from "@supabase/supabase-js";
import "./App.css";
import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { usePostHog } from 'posthog-js/react'

declare global {
  interface Window {
    rewardfulReferral: string; // or any other type that you expect it to be
  }
}

const App = () => {
  return (
    <>
      <header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a href="/" rel="home">Fleeting Notes Payments</a>
        </div>
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/success" element={<RedirectScreen isSuccess />} />
        <Route path="/cancel" element={<RedirectScreen isSuccess={false} />} />
      </Routes>
    </>
  );
};

const RedirectScreen = ({
  isSuccess,
}: { isSuccess: boolean }) => {
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "50vh" }}
    >
      <Grid item xs={3}>
        <Typography variant="h5">
          {(isSuccess)
            ? "Payment received! Sign in again to access the customer portal."
            : "Payment cancelled. Sign in to try again."}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button href={`/?price=${getPriceType()}`} variant="contained">
          Sign In
        </Button>
      </Grid>
    </Grid>
  );
};

const getPriceType = () => {
  let searchParams = new URLSearchParams(window.location.search);
  const priceType = searchParams.get("price") || "basic_monthly";
  return priceType;
};

const Home = () => {
  const posthog = usePostHog()
  const [supaUser, setSupaUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL as string,
    process.env.REACT_APP_SUPABASE_PUBLIC_KEY as string,
  );

  useEffect(() => {
    const priceType = getPriceType();
    const returnUrl = window.location.origin + `?price=${priceType}`;
    const getCheckoutSession = () => {
      let price = process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY;
      switch (priceType) {
        case "premium":
          price = process.env.REACT_APP_STRIPE_PREMIUM_PRICE_MONTHLY;
          break;
        case "premium_monthly":
          price = process.env.REACT_APP_STRIPE_PREMIUM_PRICE_MONTHLY;
          break;
        case "premium_yearly":
          price = process.env.REACT_APP_STRIPE_PREMIUM_PRICE_YEARLY;
          break;
        case "basic_yearly":
          price = process.env.REACT_APP_STRIPE_BASIC_PRICE_YEARLY;
          break;
      }
      const checkoutSession = {
        allow_promotion_codes: true,
        price, // hard code price
        success_url: window.location.origin + `/success?price=${priceType}`,
        cancel_url: window.location.origin + `/cancel?price=${priceType}`,
        client_reference_id: window.rewardfulReferral,
      };
      return checkoutSession;
    };
    const checkoutSession = async (stripeCustomerId: string, subTier: string) => {
      if (["basic", "premium"].includes(subTier)) {
        // redirect to customer portal
        const { data } = await supabase.functions.invoke(
          "stripe-customer-portal",
          { body: { returnUrl } },
        );
        if (data.url) {
          window.location.assign(data.url);
        }
      } else {
        // Redirect to stripe checkout if not a 'basic' or 'premium' user
        if (!stripeCustomerId) {
          const res = await supabase.functions.invoke("create-stripe-customer");
          if (!res.data) return;
        }
        const { data } = await supabase.functions.invoke(
          "create-stripe-checkout",
          { body: getCheckoutSession() },
        );
        if (data.url) {
          window.location.assign(data.url);
        }
      }
    };

    if (supaUser) {
      supabase.from("stripe").select().single().then((res) => {
        const stripe_customer_id = res?.data?.stripe_customer_id;
        const subscription_tier = res?.data?.subscription_tier;
        checkoutSession(stripe_customer_id, subscription_tier);
      });
    }
  }, [supaUser, supabase]);

  // const onSignOut = async () => {
  //   setLoading(true);
  //   const res = await supabase.auth.signOut();
  //   if (!res.error) {
  //     setSupaUser(null);
  //   }
  //   setLoading(false);
  // }
  const handleSignIn = async (email: string, password: string) => {
    setLoading(true);
    const errMessage = await signIn(email, password);
    setLoading(false);
    return errMessage;
  };
  const signIn = async (email: string, password: string) => {
    const supaRes = await supabase.auth.signInWithPassword({ email, password });
    if (supaRes.error) {
      return `Login Failed - ${supaRes.error.message}`;
    }
    const user = supaRes.data.user;
    setSupaUser(user);
    if (user) {
      const posthogId = posthog.get_distinct_id();
      posthog.alias(posthogId, user.id);
      posthog.capture("user login stripe");
    }
    return "";
  };

  return (
    <>
      {!supaUser && <SignIn onSignIn={handleSignIn} disabled={loading} />}
      {(supaUser) &&
        (
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "50vh" }}
          >
            <Grid item xs={3}>
              <Typography variant="h5">
                Redirecting to Stripe... (This may take a few seconds)
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default App;
